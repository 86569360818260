<script>
export default {
  props: {
    paginate: {
      type: Object,
      default: () => {
        return {
          total_pages: 1,
          page: 1,
          perPage: 10,
          total: 0,
        }
      }
    }
  },
  emits: ['changePage','change-page'],
  methods: {
    changePage(page) {
      console.log('page',page);
      this.$emit('changePage', page);
      this.$emit('change-page', page);
    }
  }
}
</script>

<template>
  <div class="row">
    <div class="col-md-6">
      <b-pagination @change="changePage" v-model="paginate.page" :total-rows="paginate.total" :per-page="paginate.perPage" aria-controls="my-table"></b-pagination>
    </div>
    <div class="col-md-6 text-right">Página {{ paginate.page }} de {{ paginate.total_pages }}</div>
  </div>
</template>

<style scoped lang="scss">

</style>